/* Navigation.css */
.custom-menu-item {
    position: relative;
    display: inline-block;
  }
  
  .custom-menu-item::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: #FF5757;
    transform-origin: center;
    transition: transform 0.3s ease-out;
  }
  
  .custom-menu-item:hover::after {
    transform: scaleX(1);
    transform-origin: center;
  }
  
  /* For mobile dropdown animations */
  @keyframes slideDown {
    from {
      max-height: 0;
      opacity: 0;
    }
    to {
      max-height: 1000px;
      opacity: 1;
    }
  }
  
  @keyframes slideUp {
    from {
      max-height: 1000px;
      opacity: 1;
    }
    to {
      max-height: 0;
      opacity: 0;
    }
  }